<template>
  <span>
    <Link :item="item"
          :status="status"
          v-if="item.children.length === 0" />
    <span v-else>
      <a-badge color="white"
               :text="item.name"
               class="project-link"
               @click="visible = !visible" />
      <span @click="visible = !visible"
            style="margin-left:2px;cursor:pointer">
        <a-icon type="plus-square"
                v-if="!visible"
                style="color:red" />
        <a-icon type="minus-square"
                v-else
                style="color:red" />
      </span>

      <ul v-if="visible">
        <li v-for="(ele, idx) in item.children"
            :key="idx"
            style="padding-left: 1.5em;">
          <Link :item="ele"
                :preStatus="item.children[idx-1].status"
                :prepreStatus="item.children[idx-2].status"
                v-if="idx>1"
                :status="ele.status" />
          <Link :item="ele"
                :preStatus="item.children[idx-1].status"
                v-else-if="idx>0"
                :status="ele.status" />
          <Link :item="ele"
                v-else
                :status="ele.status" />
        </li>
      </ul>
    </span>

  </span>
</template>

<script>
import Link from "./link.vue";
export default {
  components: {
    Link,
  },
  props: {
    item: {
      type: Object,
    },

    status: {
      type: String,
    },
    /* data: {
      type: Array,
    }, */
  },
  data() {
    return {
      visible: false,
    };
  },
};
</script>

