<template>
  <div class="log">
    <div style="padding: 12px">
      <a-list item-layout="horizontal"
              :data-source="selectedList"
              :pagination="{
          pageSize: 5,
          total,
          showTotal: (total) => `共 ${total} 条记录`,
        }">
        <a-list-item slot="renderItem"
                     slot-scope="item">
          <a-list-item-meta>
            <div slot="title"
                 style="color: #1890ff;">
              {{ item.logDate }}
            </div>
            <div slot="description">
              <span style="padding-left:20px;">
                <span class="epc-theme">{{item.content.name}}</span>{{item.content.opt}}
                <span class="stage epc-theme"
                      @click="goTo(item.content.stage, item.content.id)">{{item.content.stageName}}</span></span>
            </div>
          </a-list-item-meta>
        </a-list-item>
      </a-list>
    </div>
  </div>
</template>

<script>
import { fetchList } from "@/api/setting/log";
import moment from "moment";
import { stageList } from "../stageList.js";

export default {
  props: {
    id: {
      type: String,
      default: "",
    },
  },

  watch: {
    id() {
      this.getList();
    },
  },

  data() {
    return {
      current: 1,

      selectedList: [],
      total: 0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      fetchList({
        pageNum: this.current,
        pageSize: 100,
        conds: [
          {
            condName: "businessKey",
            condValue: [`EPC_Project:${this.id}`],
          },
        ],
      }).then((res) => {
        if (Array.isArray(res.list)) {
          let list = res.list;
          this.getSelectedList(list);
        }
      });
    },

    getSelectedList(list) {
      this.selectedList = list.filter((item) => {
        let recentDay = moment().subtract(1, "months");
        return moment(item.logDate).isSameOrAfter(recentDay, "day");
      });

      this.selectedList = this.selectedList.map((item) => {
        let m = JSON.parse(item.businessContent);

        return {
          ...item,
          content: {
            name: m.userName || "",
            opt: m.opt || "",
            stageName: m.content || "",
            stage: m.stage || "", //阶段值
            id: m.id || "", //阶段值
          },
        };
      });

      this.total = this.selectedList.length;
    },

    goTo(stage, id) {
      // 根据stagevalue 查找对应路由
      if (stage === "epc_project") {
        // 若是组织机构建立
        this.$router.push("/epc/project/update?id=" + id);
      }

      let stagePath =
        stageList.find((item) => item?.value === stage)?.path || "";

      if (stagePath) {
        this.$router.push(stagePath + "&id=" + id);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.log {
  padding: 10px 20px !important;
  background-color: #fafbfb !important;
  .ant-list-item {
    border: 1px solid #e8e8e8;
    padding: 16px 20px;
    margin-bottom: 20px;
    background-color: #fff;
  }
}

.stage {
  cursor: pointer;
}
</style>
