<template>
  <a-badge :color="customStatus"
           :text="item.name"
           id="project-link"
           @click="clickHandle(item.path)" />
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
    status: {
      type: String,
      default: "",
    },
    preStatus: {
      type: String,
      default: "",
    },
    prepreStatus: {
      type: String,
      default: "",
    },
  },

  computed: {
    customStatus() {
      if (this.status === "reviewing") {
        return "blue";
      } else if (this.status === "approved" || this.status === "not_review") {
        // 状态为审核通过或者无需审核，则为success
        return "green";
      } else if (this.status === "rejected") {
        return "yellow";
      } else if (this.status === "final_rejected") {
        return "red";
      } else if (this.status === "cancelled") {
        return "grey";
      } else {
        return "#fff";
      }
    },
  },
  methods: {
    clickHandle(path) {
      console.log(path);
      if (!path) {
        return;
      }

      // 检查投标控制预算审核意见表是否已审批，若已审批，则可以开始投标控制预算审批表
      const tempPath = path.split("&")[0];

      // 前一个阶段是否审批通过标识，通过为true，不通过为false
      let preStatus = false;
      if (this.preStatus === "approved" || this.preStatus === "not_review") {
        preStatus = true;
      }
      const isbudgetApproval = tempPath === "/epc/project/budget?activeKey=1"; //是否为投标控制预算审批表
      const isriskPath = tempPath === "/epc/project/budget?activeKey=2"; //是否为风控意见
      const ismeetPath = tempPath === "/epc/project/budget?activeKey=3"; //是否为经决会意见

      if ((isbudgetApproval || isriskPath) && !preStatus) {
        this.$message.error("请等待上一个环节结束审批！");
        return;
      }

      if (
        ismeetPath &&
        !(
          this.prepreStatus === "approved" || this.prepreStatus === "not_review"
        )
      ) {
        this.$message.error("请等待投标控制预算审批表结束审批！");
        return;
      }

      // 实施控制预算， 成本执行报告，竣工结算，分包结算页面前后顺序
      //管理计划，施工进度计划，页面顺序
      const isPlanBudget = tempPath === "/epc/plan/budget?activeKey=1"; //实施控制预算审核
      const isCER =
        tempPath === "/epc/incomeExpense/costExecutionReport?activeKey=1"; //成本执行报告审核
      const isCS =
        tempPath === "/epc/incomeExpense/completeSettlement?activeKey=1"; //竣工结算审核
      const isSS =
        tempPath === "/epc/incomeExpense/subpackageSettlement?activeKey=1"; //分包结算审核
      const isMP =
        tempPath === "/epc/plan/managePlan?activeKey=1"; //管理计划审核
      const isCP =
        tempPath === "/epc/plan/constructionSchedule?activeKey=1"; //施工进度计划审核

      if ((isPlanBudget || isCER || isCS || isSS || isMP|| isCP) && !preStatus) {
        this.$message.error("请等待上一个环节结束审批！");
        return;
      }

      // 到这里，说明前一个阶段审批通过，用于判断tab页是否可切换. 审批通过或无需审批设置为true，否则为false
      let able = this.status === "approved" || this.status === "not_review";

      if (
        isPlanBudget ||
        isCER ||
        isCS ||
        isSS ||
        isbudgetApproval ||
        isriskPath ||
        ismeetPath || isMP|| isCP
      ) {
        this.$router.push(path + "&able=true");
      } else {
        this.$router.push(path + "&able=" + able);
      }
    },
  },
};
</script>
<style lang="less">
#project-link {
  cursor: pointer;
  .ant-badge-status-text {
    font-size: 12px;
    &:hover {
      color: #1890ff;
    }
  }
}
</style>