<template>
  <a-card :title='title'
          :bodyStyle="{ height: height }"
          class="abstract">
    <a slot="extra"
       @click.prevent="goToMore">
      更多
      <a-icon type="caret-right" />
    </a>
    <p v-for="(item, index) in list"
       :key="index"
       class="abstract">
      <template>
        <a-row type="flex"
               justify="space-between"
               style="cursor:pointer">
          <a-col :span="18"
                 @click="goToMore">
            <span class="name"
                  v-if="item.userName">{{ item.userName }}</span>
            {{ item.title }}
          </a-col>
          <a-col :span="6">{{ item.date }}</a-col>
        </a-row>
      </template>
    </p>
    <p v-if="!list.length">无</p>
  </a-card>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: "106px",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    goToMore() {
      this.$emit("click", this.title);
    },
  },
};
</script>
<style lang="less">
.abstract {
  .ant-col {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .name {
      color: #1890ff;
    }
  }
  .ant-col-6 {
    text-align: end;
  }
}
</style>
