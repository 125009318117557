export function arrayToTree(list, parentID) {
  // 定义一个用于递归查找子元素的函数
  const child = function (parentID) {
    // 先定义一个数组，用于存储所查到的子元素
    const childs = [];
    // 循环数组
    for (let i = 0; i < list.length; i++) {
      // 如果数组其中一项的parentId等于传入的，说明这一项是传入的子元素，把他push进数组，然后重复递归自己找该项的子元素
      if (list[i].parentId === parentID) {
        list[i].children = child(list[i].id);
        childs.push(list[i]);
      }
    }
    // 最后将查到的所有子元素返回
    return childs;
  };
  return child(parentID);
}