<template>
  <div style="display:flex;flex-direction: column;margin-left: 5px;">
    <a-icon type="caret-up"
            style="color:#bfbfbf; cursor:pointer"
            @click="onClickUp"
            :style="{ color: current === 'up' ? '#1890ff' : '#bfbfbf' }"
            v-if="up" />
    <a-icon type="caret-down"
            style="color:#bfbfbf; cursor:pointer"
            @click="onClickDown"
            :style="{ color: current === 'down' ? '#1890ff' : '#bfbfbf' }"
            v-if="down" />
  </div>
</template>
<script>
export default {
  props: {
    up: {
      type: Boolean,
      default: true,
    },
    down: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      current: "",
    };
  },
  methods: {
    onClickUp() {
      if (this.current === "up") {
        this.current = "";
        this.$emit("reset");
        return;
      }
      this.current = "up";
      this.$emit("clickUp");
    },
    onClickDown() {
      if (this.current === "down") {
        this.current = "";
        this.$emit("reset");
        return;
      }

      this.current = "down";
      this.$emit("clickDown");
    },
  },
};
</script>
<style lang="less" scoped>
.active {
  color: #1890ff;
}
</style>
