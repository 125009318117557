export const stageList = [
  { name: "启动", id: 1, parentId: 0, value: "epc_start" },
  {
    name: "组织机构建立",
    path: `/epc/project/view?pid=`,
    value: "epc_project", //value值需要唯一,与数据字典值保持一致，除采购总计划及实施控制预算
    id: 8,
    parentId: 1,
  }, //组织机构建立没有path，默认不跳转
  {
    name: "投标阶段",
    path: `/epc/project/budget?pid=`,
    id: 9,
    parentId: 1,
    value: "epc_budget",
  },

  {
    name: "策划",
    id: 2,
    parentId: 0,
    value: "epc_planning",
  },
  {
    name: "管理目标责任书",
    path: `/epc/plan/manageObjective?pid=`,
    value: "epc_manage_objective",
    id: 10,
    parentId: 2,
  },
  {
    name: "管理计划",
    path: `/epc/plan/managePlan?pid=`,
    value: "epc_manage_plan",
    id: 11,
    parentId: 2,
  },
  {
    name: "施工进度计划",
    path: `/epc/plan/constructionSchedule?pid=`,
    value: "epc_construct_progress",
    id: 12,
    parentId: 2,
  },
  {
    name: "实施控制预算编制",
    path: `/epc/plan/budget?pid=`,
    value: "epc_implementation_budget1",
    id: 13,
    parentId: 2,
  },

  { name: "设计", id: 3, parentId: 0, value: "epc_design" },
  {
    name: "设计成果确认",
    path: `/epc/design/ResultConfirm?pid=`,
    value: "epc_result_confirm",
    id: 14,
    parentId: 3,
  },
  {
    name: "设计交底说明书",
    path: `/epc/design/DisclosureReport?pid=`,
    value: "epc_disclosure_manual",
    id: 15,
    parentId: 3,
  },
  {
    name: "设计变更审批",
    path: `/epc/design/ChangeApproval?pid=`,
    value: "epc_change_review",
    id: 16,
    parentId: 3,
  },
  {
    name: "设计完工报告",
    path: `/epc/design/CompletionReport?pid=`,
    value: "epc_completion_report",
    id: 17,
    parentId: 3,
  },

  { name: "施工", id: 4, parentId: 0, value: "epc_construction" },
  {
    name: "施工组织设计",
    path: `/epc/construction/organizeDesign?pid=`,
    value: "epc_construction_build",
    id: 18,
    parentId: 4,
  },
  {
    name: "开工审查",
    path: `/epc/construction/commenceReview?pid=`,
    value: "epc_construction_start_review",
    id: 19,
    parentId: 4,
  },
  {
    name: "施工过程",
    path: `/epc/construction/process?pid=`,
    id: 20,
    parentId: 4,
    value: "epc_construction_process",
  },
  {
    name: "质量检查",
    path: `/epc/construction/qualityInspect?pid=`,
    value: "epc_construction_quality_inspection",
    id: 21,
    parentId: 4,
  },
  {
    name: "安全检查",
    path: `/epc/construction/safety?pid=`,
    value: "22",
    id: 22,
    parentId: 4,
  },
  {
    name: "资料检查",
    path: `/epc/construction/dataInspect?pid=`,
    value: "epc_construction_document_review",
    id: 23,
    parentId: 4,
  },
  {
    name: "索赔与反索赔",
    path: `/epc/construction/claim?pid=`,
    value: "epc_construction_claim",
    id: 24,
    parentId: 4,
  },
  {
    name: "分包签证",
    path: `/epc/construction/visa?pid=`,
    value: "epc_subpackaged_visa",
    id: 25,
    parentId: 4,
  },
  {
    name: "生产安全事故快报",
    path: `/epc/construction/accident?pid=`,
    value: "epc_construction_security_accident",
    id: 26,
    parentId: 4,
  },
  {
    name: "安全违章",
    path: `/epc/construction/violation?pid=`,
    value: "epc_construction_security_rule",
    id: 27,
    parentId: 4,
  },

  { name: "采购", id: 5, parentId: 0, value: "epc_purchase" },
  {
    name: "采购总计划",
    path: `/epc/purchase/masterPlan?pid=`,
    value: "epc_purchase_plan_master1",
    id: 28,
    parentId: 5,
  },
  {
    name: "单一来源采购",
    path: `/epc/purchase/singleSource?pid=`,
    value: "epc_purchase_pre_bid",
    id: 29,
    parentId: 5,
  },
  {
    name: "招标采购",
    path: `/epc/purchase/bidding?pid=`,
    value: "epc_purchase_bid",
    id: 30,
    parentId: 5,
  },
  {
    name: "询价采购",
    path: `/epc/purchase/inquiry?pid=`,
    value: "epc_purchase_inquiry",
    id: 31,
    parentId: 5,
  },
  {
    name: "应急采购",
    path: `/epc/purchase/emergancyPurchase?pid=`,
    value: "epc_purchase_emergency",
    id: 32,
    parentId: 5,
  },
  {
    name: "转供",
    path: `/epc/purchase/transfer?pid=`,
    value: "epc_purchase_transfer",
    id: 33,
    parentId: 5,
  },
  {
    name: "返厂",
    path: `/epc/purchase/returnToFactory?pid=`,
    value: "epc_purchase_return",
    id: 34,
    parentId: 5,
  },
  {
    name: "主合同签订",
    path: `/epc/project/mainContractAssign?pid=`,
    value: "epc_purchase_main_contract_sign",
    id: 35,
    parentId: 1,
  },
  {
    name: "主合同执行",
    path: `/epc/project/mainContractImplement?pid=`,
    value: "epc_purchase_main_contract_exe",
    id: 36,
    parentId: 1,
  },
  {
    name: "分包合同",
    path: `/epc/purchase/subpackageContract?pid=`,
    value: "epc_purchase_subpack_contract",
    id: 37,
    parentId: 5,
  },

  { name: "收支", id: 6, parentId: 0, value: "epc_balance" },
  {
    name: "资金收支",
    path: `/epc/incomeExpense/capital?pid=`,
    value: "epc_balance_contract",
    id: 38,
    parentId: 6,
  },
  {
    name: "竣工结算",
    path: `/epc/incomeExpense/completeSettlement?pid=`,
    value: "epc_balance_completion_settle",
    id: 39,
    parentId: 6,
  },
  {
    name: "分包结算",
    path: `/epc/incomeExpense/subpackageSettlement?pid=`,
    value: "epc_balance_subpack_settle",
    id: 40,
    parentId: 6,
  },
  {
    name: "成本执行报告",
    path: `/epc/incomeExpense/costExecutionReport?pid=`,
    value: "epc_balance_cost_exec_report",
    id: 41,
    parentId: 6,
  },
  {
    name: "现场剩余物资处理",
    path: `/epc/incomeExpense/surplusMaterialDisposal?pid=`,
    value: "epc_balance_remain_material_handling",
    id: 42,
    parentId: 6,
  },

  { name: "收尾", id: 7, parentId: 0, value: "epc_closing" },
  {
    name: "竣工与收尾",
    path: `/epc/complete/end?pid=`,
    id: 43,
    value: "epc_closing_trial_operation",
    parentId: 7,
  },
  {
    name: "总结报告编制",
    path: `/epc/complete/report?pid=`,
    id: 44,
    value: "epc_closing_final_report",
    parentId: 7,
  },
  {
    name: "管理目标考核",
    path: `/epc/complete/performanceAssessment?pid=`,
    value: "epc_closing_perform_assess_app",
    id: 45,
    parentId: 7,
  },

  // 施工过程
  {
    name: "工作联系单",
    path: `/epc/construction/process?activeKey=0&pid=`,
    value: "epc_construction_process_worksheet",
    id: 46,
    parentId: 20,
  },
  {
    name: "周度总结",
    path: `/epc/construction/process?activeKey=1&pid=`,
    value: "epc_construction_process_week",
    id: 47,
    parentId: 20,
  },
  {
    name: "月度总结",
    path: `/epc/construction/process?activeKey=2&pid=`,
    value: "epc_construction_process_month",
    id: 48,
    parentId: 20,
  },
  // 安全检查
  {
    name: "安全检查记录表",
    path: `/epc/construction/safety?activeKey=0&pid=`,
    value: "epc_construction_security",
    id: 49,
    parentId: 22,
  },
  {
    name: "标准文件",
    path: `/epc/construction/safety?activeKey=1&pid=`,
    value: "epc_construction_security_documents",
    id: 50,
    parentId: 22,
  },
  // 索赔
  {
    name: "索赔审批",
    path: `/epc/construction/claim?pid=`,
    value: "epc_construction_claim",
    id: 51,
    parentId: 24,
  },
  {
    name: "反索赔审批",
    path: `/epc/construction/claim?pid=`,
    value: "epc_construction_counter_claim",
    id: 52,
    parentId: 24,
  },
  // 分包签证
  {
    name: "分包签证事项申请",
    path: `/epc/construction/visa?activeKey=0&pid=`,
    value: "epc_construction_subpackaged_visa",
    id: 53,
    parentId: 25,
  },
  {
    name: "工程核价单审批表",
    path: `/epc/construction/visa?activeKey=1&pid=`,
    value: "epc_construction_project_price_app",
    id: 54,
    parentId: 25,
  },
  {
    name: "工程签证审批单",
    path: `/epc/construction/visa?activeKey=2&pid=`,
    value: "epc_construction_project_visa",
    id: 55,
    parentId: 25,
  },

  // 收尾
  {
    name: "试运行方案",
    path: `/epc/complete/end?activeKey=0&pid=`,
    value: "epc_closing_trial_operation_plan",
    id: 56,
    parentId: 43,
  },
  {
    name: "试运行检查",
    path: `/epc/complete/end?activeKey=1&pid=`,
    value: "epc_closing_trial_operation_inspection",
    id: 57,
    parentId: 43,
  },

  {
    name: "总结报告",
    path: `/epc/complete/report?activeKey=0&pid=`,
    value: "epc_closing_final_report",
    id: 58,
    parentId: 44,
  },
  {
    name: "总结报告评审",
    path: `/epc/complete/report?activeKey=1&pid=`,
    value: "epc_closing_final_report_review",
    id: 59,
    parentId: 44,
  },

  {
    name: "采购总计划",
    path: `/epc/purchase/masterPlan?activeKey=0&pid=`,
    value: "epc_purchase_plan_master",
    id: 60,
    parentId: 28,
  },
  {
    name: "总计划调整",
    path: `/epc/purchase/masterPlan?activeKey=1&pid=`,
    value: "epc_purchase_plan_mod",
    id: 61,
    parentId: 28,
  },

  {
    name: "邀请招标",
    path: `/epc/purchase/bidding?activeKey=1&pid=`,
    value: "epc_purchase_bid_invited",
    id: 62,
    parentId: 30,
  },
  {
    name: "公开招标",
    path: `/epc/purchase/bidding?activeKey=0&pid=`,
    value: "epc_purchase_bid_public",
    id: 63,
    parentId: 30,
  },
  {
    name: "竞争性谈判",
    path: `/epc/purchase/bidding?activeKey=2&pid=`,
    value: "epc_purchase_bid_comp",
    id: 64,
    parentId: 30,
  },

  {
    name: "前置预招标",
    path: `/epc/purchase/singleSource?activeKey=0&pid=`,
    value: "epc_purchase_pre_bid",
    id: 65,
    parentId: 29,
  },
  {
    name: "不满足前置预招标",
    path: `/epc/purchase/singleSource?activeKey=1&pid=`,
    value: "epc_purchase_no_pre_bid",
    id: 66,
    parentId: 29,
  },

  {
    name: "收支台账",
    path: `/epc/incomeExpense/capital?activeKey=0&pid=`,
    value: "epc_balance_contract",
    id: 67,
    parentId: 38,
  },
  {
    name: "资金预测表",
    path: `/epc/incomeExpense/capital?activeKey=1&pid=`,
    value: "epc_balance_fund_forecast",
    id: 68,
    parentId: 38,
  },
  {
    name: "分包付款审核表",
    path: `/epc/incomeExpense/capital?activeKey=2&pid=`,
    value: "epc_balance_subpack_pay",
    id: 69,
    parentId: 38,
  },
  {
    name: "分包支出申请",
    path: `/epc/incomeExpense/capital?activeKey=3&pid=`,
    value: "epc_balance_subpack_expense_app",
    id: 70,
    parentId: 38,
  },
  {
    name: "投标控制预算审核意见表",
    path: `/epc/project/budget?activeKey=0&pid=`,
    value: "epc_budget_review",
    id: 71,
    parentId: 9,
  },
  {
    name: "投标控制预算审批表",
    path: `/epc/project/budget?activeKey=1&pid=`,
    value: "epc_budget",
    id: 72,
    parentId: 9,
  },
  {
    name: "风控意见",
    path: `/epc/project/budget?activeKey=2&pid=`,
    value: "epc_budget_risk",
    id: 73,
    parentId: 9,
  },
  {
    name: "经决会意见",
    path: `/epc/project/budget?activeKey=3&pid=`,
    value: "epc_budget_meeting",
    id: 74,
    parentId: 9,
  },
  {
    name: "实施控制预算审核",
    path: `/epc/plan/budget?activeKey=0&pid=`,
    value: "epc_implementation_budget_review",
    id: 75,
    parentId: 13,
  },
  {
    name: "实施控制预算审批",
    path: `/epc/plan/budget?activeKey=1&pid=`,
    value: "epc_implementation_budget",
    id: 76,
    parentId: 13,
  },
  {
    name: "成本执行报告审核",
    path: `/epc/incomeExpense/costExecutionReport?activeKey=0&pid=`,
    value: "epc_balance_cost_exec_report_review",
    id: 77,
    parentId: 41,
  },
  {
    name: "成本执行报告审批",
    path: `/epc/incomeExpense/costExecutionReport?activeKey=1&pid=`,
    value: "epc_balance_cost_exec_report",
    id: 78,
    parentId: 41,
  },
  {
    name: "竣工结算审核",
    path: `/epc/incomeExpense/completeSettlement?activeKey=0&pid=`,
    value: "epc_balance_completion_settle_review",
    id: 79,
    parentId: 39,
  },
  {
    name: "竣工结算申请报告审批表",
    path: `/epc/incomeExpense/completeSettlement?activeKey=1&pid=`,
    value: "epc_balance_completion_settle",
    id: 80,
    parentId: 39,
  },
  {
    name: "分包结算审核",
    path: `/epc/incomeExpense/subpackageSettlement?activeKey=0&pid=`,
    value: "epc_balance_subpack_settle_review",
    id: 81,
    parentId: 40,
  },
  {
    name: "分包结算审批",
    path: `/epc/incomeExpense/subpackageSettlement?activeKey=1&pid=`,
    value: "epc_balance_subpack_settle",
    id: 82,
    parentId: 40,
  },
  {
    name: "管理计划审批",
    path: `/epc/plan/managePlan?activeKey=0&pid=`,
    value: "epc_manage_plan",
    id: 83,
    parentId: 11,
  },
  {
    name: "管理计划变更审批",
    path: `/epc/plan/managePlan?activeKey=1&pid=`,
    value: "epc_manage_plan_update",
    id: 84,
    parentId: 11,
  },
  {
    name: "施工进度计划审批",
    path: `/epc/plan/constructionSchedule?activeKey=0&pid=`,
    value: "epc_construct_progress",
    id: 85,
    parentId: 12,
  },
  {
    name: "施工进度计划变更审批",
    path: `/epc/plan/constructionSchedule?activeKey=1&pid=`,
    value: "epc_construct_progress_update",
    id: 86,
    parentId: 12,
  },
];
