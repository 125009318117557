<template>
  <div>
    <a-card class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model layout="inline" @keyup.enter.native="query">
            <a-form-model-item>
              <CascaderMultipleSelect
                allowClear
                ref="participants"
                :maxTagCount="3"
                :maxTagTextLength="5"
                placeholder="项目阶段"
                v-model="form.stages"
                :selectOptionsConfig="{
                  key: 'value',
                  value: 'value',
                  text: 'name',
                  children: 'children',
                }"
                allText="全选"
                noDataText="暂无数据"
                dropdownClassName="customer-multiple-cascader"
                style="min-width: 100px"
                :treeData="stageTreeList"
                @handleCheckboxChange="handleCheckboxChange"
              />
            </a-form-model-item>

            <a-form-model-item>
              <a-select
                allowClear
                placeholder="审批状态"
                v-model="form.reviewStatus"
                show-search
                mode="multiple"
                :maxTagCount="1"
                style="width: 180px"
              >
                <a-select-option
                  v-for="item in taskReviewList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-select
                placeholder="提交人"
                v-model="form.user"
                show-search
                style="width: 200px"
                allowClear
              >
                <a-select-option
                  v-for="item in memberList"
                  :key="item.id"
                  :value="item.userId"
                  >{{
                    item.userName + "-" + item.roleName + "-" + item.deptName
                  }}</a-select-option
                >
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-range-picker v-model="form.date" style="width: 200px" />
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-button-group>
              <a-tooltip title="查询">
                <a-button @click="query" type="primary">查询</a-button>
              </a-tooltip>
              <a-tooltip title="重置">
                <a-button @click="reset">重置</a-button>
              </a-tooltip>
            </a-button-group>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-space>
          <a-button type="danger" @click="deleteBatch">删除</a-button>
          <a-button type="primary" @click="exportFile">一键导出</a-button>
        </a-space>
      </a-row>
      <Padding />
      <a-table
        bordered
        :dataSource="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        rowKey="id"
        :row-selection="{
          selectedRowKeys,
          onChange: onSelectChange,
        }"
        :sortDirections="['descend', 'ascend']"
        :sortOrder="['ascend', 'descend', false]"
      >
        <a-table-column align="center" data-index="stage">
          <div slot="title" style="display: flex; align-items: center">
            <span>项目阶段</span>
            <SortButton
              :up="false"
              ref="stageRef"
              @clickDown="clickStageDown"
              @reset="cancelStageSort"
            />
          </div>
          <template v-slot:customRender="stage">
            <DataDictFinder dictType="epc_stage" :dictValue="stage" />
          </template>
        </a-table-column>
        <a-table-column title="附件" align="center">
          <template slot-scope="text">
            <div
              class="link"
              v-for="item in text.attachmentList"
              :key="item.id"
            >
              <a target="_blank" download :href="item.completePath">{{
                item.name
              }}</a>
            </div>
          </template>
        </a-table-column>
        <a-table-column title="状态" align="center" data-index="reviewStatus">
          <template v-slot="reviewStatus">
            <DataDictFinder
              dictType="epc_review_status"
              :dictValue="reviewStatus"
            />
          </template>
        </a-table-column>
        <a-table-column
          title="提交人"
          align="center"
          data-index="createByName"
        />
        <a-table-column align="center">
          <div slot="title" style="display: flex; align-items: center">
            <span>操作日期</span>
            <SortButton
              @clickUp="clickTimeUp"
              ref="timeButtonRef"
              @clickDown="clickTimeDown"
              @reset="cancelSort"
            />
          </div>
          <template slot-scope="text">{{ getTime(text.lastOptAt) }}</template>
        </a-table-column>
        <a-table-column key="control" title="操作" align="center">
          <template v-slot="text">
            <a-space>
              <a @click="edit(text)" v-if="text.reviewStatus !== 'reviewing'"
                >编辑</a
              >
              <a v-else class="disabled">编辑</a>
              <a @click="view(text)">查看</a>
              <!-- 有值时，才展示审批详情 -->
              <a
                v-if="text.procTaskId"
                @click="
                  $router.push(
                    `/approval/task/detail?taskId=${text.procTaskId}&instanceId=${text.procInstanceId}&status=${text.procNodeStatus}`
                  )
                "
                >审核详情</a
              >
              <a v-else class="disabled">审批详情</a>
              <a class="danger" @click.prevent="del(text.id)">删除</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import CascaderMultipleSelect from "@/components/CascaderMultipleSelect";
import SortButton from "./components/SortButton";

import moment from "moment";
import { mapGetters, mapState } from "vuex";

import { fetchList, remove, removeBatch } from "@/api/epc/historyFile";
import { exportBatch, fetchMembers } from "@/api/epc";
import { arrayToTree } from "@/utils/arrayToTree";
import { stageList } from "../stageList";

import { saveAs } from "file-saver";

const stageTreeList = arrayToTree(stageList, 0);
console.log(stageTreeList);

export default {
  components: {
    CascaderMultipleSelect,
    SortButton,
  },

  props: {
    id: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      form: {},
      memberList: [],
      loading: false,
      list: [],
      total: 0,
      current: 1,
      pageSize: 10,
      desc: "",
      orderKey: "",

      selectedRowKeys: [],
      selectRowsId: [],

      path: "",

      stageTreeList: [stageTreeList],
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    ...mapState("auth", ["menuList"]),
    //epc总阶段
    stageGpList() {
      return this.findDataDict("epc_gp_stage");
    },
    //epc中间阶段
    stagePList() {
      return this.findDataDict("epc_p_stage");
    },

    // epc详细阶段
    epcStageList() {
      return this.findDataDict("epc_stage");
    },

    taskReviewList() {
      return this.findDataDict("epc_review_status");
    },
  },

  watch: {
    id() {
      this.getList();
      this.getMembers();
    },
  },
  mounted() {
    this.getList();
    this.getMembers();
  },

  methods: {
    getTime(time) {
      return moment(time).format("YYYY-MM-DD HH:mm:ss");
    },
    getList() {
      this.loading = true;
      let { current, pageSize, form } = this;
      let { reviewStatus, stages, date, user } = form;

      let startDate = "",
        endDate = "";
      if (Array.isArray(date) && date.length === 2) {
        startDate = date[0].format("YYYY-MM-DD");
        endDate = date[1].format("YYYY-MM-DD");
      }

      let selectedStages = [];
      if (Array.isArray(stages)) {
        selectedStages = this.getSelectedStages(stages);
      }
      console.log(selectedStages);

      let params = {};
      //desc只针对order为time时生效。为空切orderKey不为空时，进行阶段排序
      if (this.desc === "" && this.orderKey !== "") {
        params = {
          pageNum: current,
          pageSize: pageSize,
          prjId: this.id,
          createBy: user,
          reviewStatus,
          stages: selectedStages,
          order: "stage",
        };
      } else if (this.desc === "") {
        //不排序
        params = {
          pageNum: current,
          pageSize: pageSize,
          prjId: this.id,
          createBy: user,
          reviewStatus,
          stages: selectedStages,
        };
      } else {
        //时间排序
        params = {
          pageNum: current,
          pageSize: pageSize,
          prjId: this.id,
          createBy: user,
          reviewStatus,
          stages: selectedStages,
          startDate,
          endDate,
          desc: this.desc,
          order: "createAt",
        };
      }

      console.log(params);
      fetchList(params)
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async getMembers() {
      console.log(this.id);
      let res = await fetchMembers({
        pageNum: 1,
        pageSize: 1000,
        pid: this.id,
      });
      this.memberList = res.list.filter((ele) => ele.userId && ele.userName);
    },

    getSelectedStages(stages) {
      // 总阶段
      // epc_gp_stage
      // 中间阶段 epc_p_stage
      // 详细阶段 epc_stage
      // 总阶段与中间阶段、详细阶段值可能有重复
      let res = [];
      for (let item of stages) {
        // 若是总阶段
        let gpStage = stageTreeList.find((i) => i.value === item);
        if (gpStage) {
          // 添加总阶段下的所有三级阶段，以及无三级阶段的二级阶段的值
          for (let ele of gpStage.children) {
            if (ele.children.length > 0) {
              let arr = ele.children.map((i) => {
                return i.value;
              });
              res.push(...arr);
            } else {
              res.push(ele.value);
            }
          }
        } else if (
          this.stagePList.findIndex(
            (i) =>
              i.value !== "epc_implementation_budget" &&
              i.value !== "epc_purchase_plan_master" &&
              i.value === item
          ) > 0 ||
          item === "epc_implementation_budget1" ||
          item === "epc_purchase_plan_master1"
        ) {
          // 中间阶段,采购总计划及实施控制预算(因为值与数据字典不一致)
          // 找到对应的treeList中间阶段，便于找后代

          for (let ele of stageTreeList) {
            let pstage = ele.children.find((i) => i.value === item);
            console.log(pstage);
            if (pstage) {
              // 若有三级阶段,只返回三级阶段的值
              if (pstage.children.length) {
                let arr = pstage.children.map((i) => i.value);
                res.push(...arr);
              } else {
                //若没有三级阶段,则返回中间阶段的值
                res.push(pstage.value);
              }
              break;
            }
          }
        } else {
          //详细阶段
          res.push(item);
        }
      }

      return res;
    },
    clickTimeUp() {
      this.desc = 0;
      this.orderKey = "createAt";

      // 将项目阶段的按钮置为非活跃状态
      this.$refs.stageRef.current = "";

      this.getList();
    },
    clickTimeDown() {
      this.desc = 1;
      this.orderKey = "createAt";

      // 将项目阶段的按钮置为非活跃状态
      this.$refs.stageRef.current = "";
      this.getList();
    },

    clickStageDown() {
      this.desc = "";
      this.orderKey = "stage";

      // 将项目阶段的按钮置为非活跃状态
      this.$refs.timeButtonRef.current = "";
      this.getList();
    },
    // 取消时间排序
    cancelSort() {
      this.desc = "";
      this.orderKey = "";
      this.getList();
    },
    // 取消状态排序
    cancelStageSort() {
      this.orderKey = "";
      this.getList();
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },
    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
    del(id) {
      const that = this;

      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({
            id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
    deleteBatch() {
      var that = this;

      this.$confirm({
        title: `确认要删除以下${this.selectRowsId.length}项内容吗？`,
        onOk() {
          removeBatch(this.selectRowsId).then(() => {
            that.getList();
          });
        },
      });
    },
    exportFile() {
      let params = {
        historyList: this.selectedRowKeys,
        prjId: this.id,
      };
      exportBatch(params)
        .then((blob) => {
          saveAs(blob, `文件.zip`);
        })
        .catch();
    },

    onSelectChange(keys, text) {
      this.selectRowsId = [];
      this.selectedRowKeys = keys;
      let id;
      for (let item of text) {
        id = item.id;
        this.selectRowsId.push({ id });
      }
    },

    handleCheckboxChange(val) {
      console.log(val);
    },

    findPath(text) {
      let stageName =
        this.epcStageList.find((item) => item.value === text.stage)?.name || "";
      console.log(stageName, text.stage);
      let path = "";

      // 有tab页的单独设置path
      switch (text.stage) {
        case "epc_project": //组织机构建立
          path = "/epc/project/update?";
          break;
        case "epc_construction_process_worksheet": //工作联系单
          path = "/epc/construction/process?activeKey=0&";
          break;
        case "epc_construction_process_week": //周度总结
          path = "/epc/construction/process?activeKey=1&";
          break;
        case "epc_construction_process_month": //月度总结
          path = "/epc/construction/process?activeKey=2&";
          break;
        case "epc_construction_security": //安全检查记录表
          path = "/epc/construction/safety?activeKey=0&";
          break;
        case "epc_construction_security_documents": //标准文件
          path = "/epc/construction/safety?activeKey=1&";
          break;
        case "epc_construction_claim": //索赔
          path = "/epc/construction/claim?activeKey=0&";
          break;
        case "epc_construction_counter_claim": //反索赔
          path = "/epc/construction/claim?activeKey=1&";
          break;
        case "epc_construction_subpackaged_visa": //分包签证事项申请
          path = "/epc/construction/visa?activeKey=0&";
          break;
        case "epc_construction_project_price_app": //工程核价审批表
          path = "/epc/construction/visa?activeKey=1&";
          break;
        case "epc_construction_project_visa": //工程签证审批单
          path = "/epc/construction/visa?activeKey=2&";
          break;
        case "epc_closing_trial_operation_plan": //试运行方案
          path = "/epc/complete/end?activeKey=0&";
          break;
        case "epc_closing_trial_operation_inspection": //试运行检查
          path = "/epc/complete/end?activeKey=1&";
          break;
        case "epc_closing_final_report": //总结报告
          path = "/epc/complete/report?activeKey=0&";
          break;
        case "epc_closing_final_report_review": //总结报告评审
          path = "/epc/complete/report?activeKey=1&";
          break;
        case "epc_purchase_plan_master": //采购总计划
          path = "/epc/purchase/masterPlan?activeKey=0&";
          break;
        case "epc_purchase_plan_mod": //总计划调整
          path = "/epc/purchase/masterPlan?activeKey=1&";
          break;
        case "epc_purchase_bid_public": //公开招标
          path = "/epc/purchase/bidding?activeKey=0&";
          break;
        case "epc_purchase_bid_invited": //邀请招标
          path = "/epc/purchase/bidding?activeKey=1&";
          break;
        case "epc_purchase_bid_comp": //竞争性谈判
          path = "/epc/purchase/bidding?activeKey=2&";
          break;
        case "epc_purchase_pre_bid": //前置预招标
          path = "/epc/purchase/singleSource?activeKey=0&";
          break;
        case "epc_purchase_no_pre_bid": //不满足前置预招标
          path = "/epc/purchase/singleSource?activeKey=1&";
          break;
        case "epc_balance_contract": //收支台账
          path = "/epc/incomeExpense/capital?activeKey=0&";
          break;
        case "epc_balance_fund_forecast": //资金预测表
          path = "/epc/incomeExpense/capital?activeKey=1&";
          break;
        case "epc_balance_subpack_pay": //分包付款审核表
          path = "/epc/incomeExpense/capital?activeKey=2&";
          break;
        case "epc_balance_subpack_expense_app": //分包支出申请
          path = "/epc/incomeExpense/capital?activeKey=3&";
          break;
        case "epc_balance_completion_settle_review": //竣工结算审核
          path = "/epc/incomeExpense/completeSettlement?activeKey=0&";
          break;
        case "epc_balance_completion_settle": //竣工结算申请报告审批表
          path = "/epc/incomeExpense/completeSettlement?activeKey=1&";
          break;
        case "epc_balance_subpack_settle_review": //分包结算审核
          path = "/epc/incomeExpense/subpackageSettlement?activeKey=0&";
          break;
        case "epc_balance_subpack_settle": //分包结算审批
          path = "/epc/incomeExpense/subpackageSettlement?activeKey=1&";
          break;
        case "epc_balance_cost_exec_report_review": //成本执行报告审核
          path = "/epc/incomeExpense/costExecutionReport?activeKey=0&";
          break;
        case "epc_balance_cost_exec_report": //成本执行报告审批
          path = "/epc/incomeExpense/costExecutionReport?activeKey=1&";
          break;
        case "epc_implementation_budget_review": //实施控制预算审核
          path = "/epc/plan/budget?activeKey=0&";
          break;
        case "epc_implementation_budget": //实施控制预算审批
          path = "/epc/plan/budget?activeKey=1&";
          break;
        case "epc_budget_review": //投标控制预算审核意见表
          path = "/epc/project/budget?activeKey=0&";
          break;
        case "epc_budget": //投标控制预算审批表
          path = "/epc/project/budget?activeKey=1&";
          break;
        case "epc_budget_risk": //风控意见
          path = "/epc/project/budget?activeKey=2&";
          break;
        case "epc_budget_meeting": //经决会意见
          path = "/epc/project/budget?activeKey=3&";
          break;
        case "epc_manage_plan": //管理计划
          path = "/epc/plan/managePlan?activeKey=0&";
          break;
        case "epc_manage_plan_update": //管理计划变更
          path = "/epc/plan/managePlan?activeKey=1&";
          break;
        case "epc_construct_progress": //施工计划
          path = "/epc/plan/constructionSchedule?activeKey=0&";
          break;
        case "epc_construct_progress_update": //施工计划变更
          path = "/epc/plan/constructionSchedule?activeKey=1&";
          break;

        default:
          path =
            this.menuList.find((item) => item.name === stageName) !== undefined
              ? this.menuList.find((item) => item.name === stageName).path + "?"
              : "";
      }
      return path;
    },

    edit(text) {
      let path = this.findPath(text);
      console.log(path);
      // prjId是项目id，id是历史记录的id，pid是文件的id
      this.$router.push(
        `${path}pid=${text.prjId}&id=${text.pid}&hid=${text.id}`
      );
    },

    view(text) {
      let path = this.findPath(text);
      console.log(path);
      // prjId是项目id，id是历史记录的id，pid是文件的id
      this.$router.push(`${path}pid=${text.prjId}&id=${text.pid}&isView=true`);
    },
  },
};
</script>
<style lang="less" scoped>
.disabled {
  color: #ccc;
}
</style>
