import request from '../request'

// 查询项目阶段状态
export function stageDisplay(params) {
    return request({
        url: '/market-service/epc/common/history/stageDisplay',
        params
    })
}

export function fetchList(data) {
    return request({
        url: '/market-service/epc/common/history/list',
        method: 'post',
        data
    })
}

export function add(data) {
    return request({
        url: '/market-service/epc/common/history/add',
        method: 'post',
        data
    })
}

export function fetchDetail(params) {
  return request({
      url: `/market-service/epc/common/history/query/${params.id}`
  })
}

export function edit(data) {
    return request({
        url: '/market-service/epc/common/history/update',
        method: 'post',
        data,
    })
}


export function remove(data) {
    return request({
        url: '/market-service/epc/common/history/delete',
        method: 'post',
        data
    })
}

export function removeBatch(data) {
    return request({
        url: '/market-service/epc/common/history/delete_batch',
        method: 'post',
        data
    })
}