<template>
  <div class="pane flex">
    <div class="title">
      {{ title ? title : configData.name }}
    </div>
    <div class="pane-center center">
      <slot name="center"></slot>
    </div>
    <div class="extra">
      <slot name="extra"></slot>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      configData: {}, // 管理员在后台配置的页面数据
    };
  },
  computed: {
    ...mapGetters("auth", ["getCurrentRoute"]),
  },
  mounted() {
    const routeObj = this.getCurrentRoute(this.$route.path);
    if (routeObj) {
      this.configData = routeObj;
    }
  },

  watch: {
    $route: function (newRoute) {
      const routeObj = this.getCurrentRoute(newRoute.path);
      if (routeObj) {
        this.configData = routeObj;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.pane {
  background-color: #fff;
  padding: 16px 32px;

  justify-content: space-between;
  align-items: center;
  .title {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
  }
  .pane-center {
    width: 60%;
  }
}
</style>
