<template>
  <div id="project-detail">
    <Header v-if="!visible">
      <template v-slot:center>
        <div class="prjname text-center">{{ projectName }}</div>
      </template>
      <template v-slot:extra>
        <div class="center search-container">
          <div class="search">
            <a-input-search
              :placeholder="designCode"
              enter-button
              @input="inputDebounce"
              @search="onSearch"
              @keyup.enter.native="onSearch"
              v-model="inputContent"
            ></a-input-search>
            <div class="query" v-if="isShow">
              <p
                v-for="item in searchList"
                :key="item.id"
                @click="choose(item)"
              >
                {{ item.designCode }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </Header>

    <Pane :bottom="0" :showRemarks="false" v-else>
      <a-tabs
        v-model="activeKey"
        :tabBarStyle="{
          margin: '0',
        }"
        @change="handelTabChange"
      >
        <a-tab-pane key="最近一周" tab="最近一周"></a-tab-pane>
        <a-tab-pane key="最近一月" tab="最近一月"></a-tab-pane>
        <a-tab-pane key="文件列表" tab="文件列表"></a-tab-pane>
      </a-tabs>
    </Pane>

    <div v-show="!visible">
      <div class="container latest">
        <a-row :gutter="16">
          <a-col :lg="8" :md="8" :sm="24">
            <card
              :list="topThreeInWeek"
              title="最近一周"
              @click="goToMoreLogWeek"
            />
          </a-col>

          <a-col :lg="8" :md="8" :sm="24">
            <card
              :list="topThreeInMonth"
              title="最近一月"
              @click="goToMoreLogMonth"
            />
          </a-col>

          <a-col :lg="8" :md="8" :sm="24">
            <card
              :list="topThreeList"
              title="文件列表"
              @click="goToMoreFileList"
            />
          </a-col>
        </a-row>
      </div>

      <a-card class="container label">
        <div>
          <a-badge
            v-for="(item, index) in statusList"
            :key="index"
            :text="item.name"
            :status="item.status"
            style="margin-right: 20px"
          />
        </div>
      </a-card>

      <div class="list-container flex">
        <div
          v-for="(ele, index) in stageListByTree"
          :key="index"
          class="flex-1 list-item"
        >
          <h2>{{ ele.name }}</h2>
          <div>
            <div v-for="(item, idx) in ele.children" :key="idx">
              <LinkContainer
                :item="item"
                :status="item.status"
                :data="stageList"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container log" v-show="visible">
      <div>
        <a-icon
          type="arrow-left"
          style="font-size: 20px; color: #1890ff"
          @click="back"
        />
      </div>
      <LogInWeek ref="logInWeek" v-show="title === '最近一周'" :id="id" />
      <LogInMonth ref="logInMonth" v-show="title === '最近一月'" :id="id" />
      <FileTable ref="fileTableRef" v-show="title === '文件列表'" :id="id" />
    </div>
  </div>
</template>
<script>
import { fetchList, fetchDetail } from "@/api/epc";
import { fetchList as fetchLogList } from "@/api/setting/log";
import { stageDisplay } from "@/api/epc/historyFile";

import Header from "./component/Header.vue";
import Card from "./component/card.vue";
import LogInWeek from "./component/log-in-week.vue";
import LogInMonth from "./component/log-in-month.vue";
import FileTable from "./component/file-table.vue";
import LinkContainer from "./component/LinkContainer.vue";

import moment from "moment";
import { mapGetters } from "vuex";
import { stageList } from "./stageList.js";

import { debounce } from "lodash";

export default {
  name: "epcDetail",
  components: { Header, Card, LogInWeek, LogInMonth, FileTable, LinkContainer },
  data() {
    return {
      queryId: "",
      id: "",
      designCode: "",
      projectName: "",

      inputContent: "", //搜索框相关
      searchList: [],
      isShow: false,

      visible: false,
      activeKey: "最近一周",

      title: "", // 选中的选项卡
      topThreeInWeek: [], // 前三条最近一周的记录
      topThreeInMonth: [], // 前三条最近一月的记录
      topThreeList: [], //前三条文件记录
      fileList: [], //历史文件记录（含审核状态信息），用于设置阶段审核状态

      statusList: [
        {
          //要改成数据字典形式
          name: "审核中",
          status: "processing",
        },
        {
          name: "已完成/无需审批",
          status: "success",
        },
        {
          name: "已驳回",
          status: "warning",
        },
        {
          name: "已拒绝",
          status: "error",
        },
        {
          name: "已取消/暂存",
          status: "default",
        },
      ],

      stageList: stageList,
      stageListByTree: [],
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    epcStageList() {
      return this.findDataDict("epc_stage");
    },
  },

  watch: {
    title(newVal) {
      this.activeKey = newVal;
    },
    id(newVal) {
      this.stageList.forEach((item) => {
        if (item.path) {
          item.path = item.path.split("pid=")[0] + "pid=" + newVal;
        }
      });
    },
  },
  created() {
    // 500ms，多次触发，只保留最后一次
    this.inputDebounce = debounce(this.inputChange, 500);
  },

  activated() {
    const { query } = this.$route;
    const { pid } = query;
    this.queryId = pid || "";
    // 从其他页面返回，重新获取审批状态及日志
    this.getLatelyProj();
  },

  methods: {
    // 获取最新审核结束的项目
    getLatelyProj() {
      fetchList({
        pageNum: 1,
        pageSize: 1,
        approved: true, //包含第一次审核后的项目
      }).then(async (res) => {
        if (Array.isArray(res.list)) {
          let latelyId = res.list[0]?.id;
          const id = this.queryId || latelyId || "";

          if (!id) {
            this.$message.warning("请创建项目！");
            return;
          }

          this.id = id;
          this.getLogList();
          this.getFileList();

          // 根据确定的id获取项目编号

          // 获取编号，名称
          fetchDetail(this.id).then((res) => {
            this.designCode = res.designCode || "";
            this.inputContent = res.designCode || "";
            this.projectName = res.name || "";
          });
        }
      });
    },

    // 获取最近三个日志
    getLogList() {
      fetchLogList({
        pageNum: 1,
        pageSize: 3,
        conds: [
          {
            condName: "businessKey",
            condValue: [`EPC_Project:${this.id}`],
          },
        ],
      }).then((res) => {
        if (Array.isArray(res.list)) {
          let list = res.list;

          this.topThreeInWeek = list
            .filter((item) => {
              let recentDay = moment().subtract(1, "weeks");
              return moment(item.logDate).isSameOrAfter(recentDay, "day");
            })
            .slice(0, 3)
            .map((item) => {
              // 换成需要的形式
              const m = JSON.parse(item.businessContent);
              const title = `${m.userName}${m.opt}${m.content}`;

              return {
                date: moment(item.logDate).format("YYYY-MM-DD"),
                title,
                userName: item.userName,
              };
            });

          this.topThreeInMonth = list
            .filter((item) => {
              let recentDay = moment().subtract(1, "months");
              return moment(item.logDate).isSameOrAfter(recentDay, "day");
            })
            .slice(0, 3)
            .map((item) => {
              const m = JSON.parse(item.businessContent);
              const title = `${m.userName}${m.opt}${m.content}`;

              return {
                date: moment(item.logDate).format("YYYY-MM-DD"),
                title,
                userName: item.userName,
              };
            });
        }
      });
    },
    // 获取历史文件
    getFileList() {
      stageDisplay({
        pageNum: 1,
        pageSize: 100,
        prjId: this.id,
      }).then((res) => {
        if (Array.isArray(res.list)) {
          this.fileList = res.list;

          this.setStatus();

          let topThreeList = [];

          res.list.some((item) => {
            // 若没有附件，跳过
            if (!item.attachmentList) return;
            for (let element of item.attachmentList) {
              if (topThreeList.length === 3) return true;

              topThreeList.push({
                title: element.name,
                date: moment(item.lastOptAt).format("YYYY-MM-DD"),
              });
            }
          });

          this.topThreeList = topThreeList;
        }
      });
    },
    // 添加阶段审核状态
    setStatus() {
      this.fileList = this.fileList.map((item) => {
        return {
          stage: item.stage,
          reviewStatus: item.reviewStatus,
        };
      });
      // 根据数据字典添加name
      this.fileList.forEach((item) => {
        let name =
          this.epcStageList.find((ite) => ite.value === item.stage)?.name || "";
        item.name = name;
      });

      // 根据stage去重。stage一样，只保留第一次的值
      this.fileList = this.removeDuplicateObj(this.fileList);

      // 根据stage添加对应的审核状态
      this.stageList.forEach((item) => {
        item.status =
          this.fileList.find((ele) => ele.stage === item.value)?.reviewStatus ||
          "";
      });
      this.getstageListByTree();
    },
    // 放到utils文件夹中
    removeDuplicateObj(arr) {
      let obj = {};
      arr = arr.reduce((newArr, next) => {
        obj[next.stage] ? "" : (obj[next.stage] = true && newArr.push(next));
        return newArr;
      }, []);
      return arr;
    },
    getstageListByTree() {
      // mock
      this.stageListByTree = this.arrayToTree(this.stageList, 0); //初始值
    },
    arrayToTree(list, parentID) {
      // 定义一个用于递归查找子元素的函数
      const child = function (parentID) {
        // 先定义一个数组，用于存储所查到的子元素
        const childs = [];
        // 循环数组
        for (let i = 0; i < list.length; i++) {
          // 如果数组其中一项的parentId等于传入的，说明这一项是传入的子元素，把他push进数组，然后重复递归自己找该项的子元素
          if (list[i].parentId === parentID) {
            list[i].children = child(list[i].id);
            childs.push(list[i]);
          }
        }
        // 最后将查到的所有子元素返回
        return childs;
      };
      return child(parentID);
    },

    // 日志文件card相关
    goToMoreLogWeek(title) {
      this.visible = true;
      this.title = title;

      this.$refs.logInWeek.getList();
    },
    goToMoreLogMonth(title) {
      this.visible = true;
      this.title = title;

      this.$refs.logInMonth.getList();
    },
    goToMoreFileList(title) {
      this.visible = true;
      this.title = title;

      this.$refs.fileTableRef.getList();
    },
    handelTabChange(tab) {
      this.title = tab;
    },
    back() {
      this.visible = false;
    },

    // 搜索框相关
    inputChange() {
      this.isShow = true;
      fetchList({
        designCode: this.inputContent,
        approved: true,
      }).then((res) => {
        if (res.list) {
          this.searchList = res.list
            .map((item) => {
              return {
                designCode: item.designCode,
                id: item.id,
                name: item.name,
              };
            })
            .slice(0, 10);
        }
      });
    },
    choose(item) {
      this.isShow = false;
      this.updateProj(item);
    },
    onSearch(data) {
      this.isShow = false;
      if (!this.inputContent) {
        // 如果为空，则替换为当前的项目编号
        this.inputContent = this.designCode;
        return;
      }

      fetchList({
        designCode: data,
        approved: true,
      }).then((res) => {
        if (Array.isArray(res.list) && res.list.length > 0) {
          // 若有值，取第一个搜到的值
          this.updateProj(res.list[0]);
        } else {
          // 项目编号不存在，则替换为当前的项目编号
          this.inputContent = this.designCode;
        }
      });
    },
    updateProj(item) {
      this.designCode = item.designCode;
      this.inputContent = item.designCode;
      this.projectName = item.name;
      this.id = item.id;

      if (this.$route.query.pid !== this.id) {
        this.$router.push(this.$route.path + "?pid=" + this.id);
      }

      this.getLogList();
      this.getFileList();
    },
  },
};
</script>
<style lang="less" scoped>
.search-container {
  flex: 1;
  .search {
    position: relative;
  }
  .query {
    width: 100%;
    background-color: rgb(255, 255, 255);
    position: absolute;
    z-index: 1000;
    border: 1px solid #d9d9d9;
    p {
      padding: 0px 15px;
      cursor: pointer;
      &:hover {
        color: #1890ff;
      }
    }
    p:first-child {
      padding-top: 1em;
    }
  }
}

.label {
  margin-bottom: -7px;
  display: flex;
  justify-content: flex-end;
}

.list-container {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  margin: 8px;
}

.list-item {
  padding: 20px 10px;
  border: 1px;
  border-right: #e8e8e8 1px dashed;
  &:last-child {
    border-right: none;
  }
  h2 {
    text-align: center;
  }
  & > div {
    width: max-content;
    margin: 0 auto;
    & > div {
      padding: 5px 0;
    }
  }
}

.log {
  background-color: #fff;
  padding: 20px;
}
.prjname {
  font-size: 1.5em;
  font-weight: 700;
}
</style>

<style lang="less">
#project-detail {
  .latest {
    .ant-col {
      padding-right: 0px !important;
    }
    .ant-col:last-child {
      padding-right: 8px !important;
    }
  }

  .badge {
    .ant-badge {
      width: 8px;
    }
  }
}
</style>
